import React from "react";
import {Link} from "react-router-dom";
import "swiper/css/autoplay";
// import Swiper core and required modules
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Autoplay, EffectFade, Navigation, Pagination,} from "swiper";

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

// install Swiper modules
function HeroArea() {
    const HeroSlider = {
        slidesPerView: 1,
        speed: 1500,
        spaceBetween: 0,
        loop: "true",
        clickable: true,
        autoplay: true,
        effect: "fade",
        centeredSlides: true,
        roundLengths: true,
        fadeEffect: {
            crossFade: true,
        },
    };
    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <>
            <section className="hero-area">
                <div className="swiper hero-slider">
                    <Swiper
                        pagination={{
                            type: "bullets",
                            clickable: true,
                        }}
                        {...HeroSlider}
                        loop={true}
                        className="swiper-wrapper"
                    >
                        <SwiperSlide className="swiper-slide">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="hero-content-wrapper">
                                            <div className="hero-content-wrap">
                                                <div className="hero-content-img">
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/images/hero-slider-1.jpg"
                                                        }
                                                        alt="images"
                                                    />
                                                </div>
                                                <div className="hero-content">
                                                    <h2>AsonTechSol</h2>
                                                    <h1>
                                                        Innovative softs for you <span>Business.</span>
                                                    </h1>
                                                    <p>
                                                        Our team of skilled professionals delivers customized software
                                                        solutions to meet your business needs. Let us help you achieve
                                                        your goals with our expertise in software development.
                                                    </p>
                                                    <Link
                                                        onClick={scrollTop}
                                                        to={`${process.env.PUBLIC_URL}/about`}
                                                        className="about-btn"
                                                    >
                                                        About us
                                                    </Link>
                                                    <Link
                                                        onClick={scrollTop}
                                                        to={`${process.env.PUBLIC_URL}/project-details`}
                                                        className="work-btn"
                                                    >
                                                        How we work
                                                    </Link>
                                                    <div className="slider-num">
                                                        <span>01</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="swiper-slide">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="hero-content-wrapper">
                                            <div className="hero-content-wrap">
                                                <div className="hero-content-img">
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/images/hero-slider-2.png"
                                                        }
                                                        alt="images"
                                                    />
                                                </div>
                                                <div className="hero-content">
                                                    <h2>AsonTechSol</h2>
                                                    <h1>
                                                        Best software for your <span>Business.</span>
                                                    </h1>
                                                    <p>
                                                        Efficient software solutions tailored to your business needs.
                                                        Our experts deliver high-quality work with attention to detail
                                                        and customer satisfaction. Let us help you level up.
                                                    </p>
                                                    <Link
                                                        onClick={scrollTop}
                                                        to={`${process.env.PUBLIC_URL}/about`}
                                                        className="about-btn"
                                                    >
                                                        About us
                                                    </Link>
                                                    <Link
                                                        onClick={scrollTop}
                                                        to={`${process.env.PUBLIC_URL}/project-details`}
                                                        className="work-btn"
                                                    >
                                                        How we work
                                                    </Link>
                                                    <div className="slider-num">
                                                        <span>02</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="swiper-slide">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="hero-content-wrapper">
                                            <div className="hero-content-wrap">
                                                <div className="hero-content-img">
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            "/images/hero-slider-3.png"
                                                        }
                                                        alt="images"
                                                    />
                                                </div>
                                                <div className="hero-content">
                                                    <h2>AsonTechSol</h2>
                                                    <h1>Top-notch software based &nbsp;
                                                        <span>company.</span>
                                                    </h1>
                                                    <p>Our team of skilled professionals delivers innovative software
                                                        solutions to help you achieve your business goals. Let us help
                                                        you transform your business with our expertise in software
                                                        development.
                                                    </p>
                                                    <Link
                                                        onClick={scrollTop}
                                                        to={`${process.env.PUBLIC_URL}/about`}
                                                        className="about-btn"
                                                    >
                                                        About us
                                                    </Link>
                                                    <Link
                                                        onClick={scrollTop}
                                                        to={`${process.env.PUBLIC_URL}/project-details`}
                                                        className="work-btn"
                                                    >
                                                        How we work
                                                    </Link>
                                                    <div className="slider-num">
                                                        <span>03</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className="swiper-pagination"/>
                </div>
                <div className="social-media">
                    <ul className="social-list">
                        <li>
                            <a rel="noopener noreferrer" href="https://web.facebook.com/AsonTechSol?_rdc=1&_rdr">
                                Facebook
                            </a>
                        </li>
                        <li>
                            <a rel="noopener noreferrer" href="https://www.instagram.com/asontechsol/">
                                instagram
                            </a>
                        </li>
                        <li>
                            <a rel="noopener noreferrer" href="https://www.linkedin.com/company/asontech-solutions/">
                                Linked in
                            </a>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    );
}

export default HeroArea;
