import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import LetsTalkArea from "../../common/LetsTalkArea";
import TestimonialArea from "../../common/TestimonialArea";
import WhyChooseUsArea from "../../common/WhyChooseUsArea";
import InsideStoryArea from "./InsideStoryArea";
import LatesNewsArea from "./LatesNewsArea";
import About2Area from "../Home2/About2Area";
import PortfolioArea from "../Home/PortfolioArea";

function AboutPage() {
    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <>
            <div className="dark">
                <Breadcrumb pageName="about"/>
                <InsideStoryArea black="" light="dark" lable="progressbar-label"/>
                <About2Area />
                <PortfolioArea/>
                <TestimonialArea/>
                <WhyChooseUsArea black="" light="dark" lable="progressbar-label" />
                <LatesNewsArea/>
                <LetsTalkArea/>
            </div>
        </>
    );
}

export default AboutPage;
