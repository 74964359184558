import React from "react";
import "react-circular-progressbar/dist/styles.css";

function InsideStoryArea() {
    const Singlepercentage = 90;
    const singleXmPercentage = 95;
    const singleXmlPercentage = 88;
    const circlebar = {
        text: {
            fill: "#f88",
            fontSize: "16px",
        },
    };
    return (
        <>
            <section className="about-area sec-mar">
                <div className="container">
                    <div className="out-story">
                        <div className="row">
                            <div className="col-lg-6 col-xl-6">
                                <div className="story-left">
                                    <div className="office-group-img">
                                        <img
                                            src={process.env.PUBLIC_URL + "/images/story.png"}
                                            alt="images"
                                        />
                                        <div className="cto-message-wrapper">
                                            <div className="cto-message" style={{background: '#212529'}}>
                                                <p className="text-white">
                                                    Integer purus odio, placerat neclessi rhoncus in,
                                                    ullamcorper nec dolor.ol aptent taciti sociosqu.
                                                </p>
                                                <h4 className="text-white">
                                                    Asad Khan. <span style={{color: '#D90A2C', fontWeight: '1000'}}>CEO - AsonTechSol</span>
                                                </h4>
                                                <img
                                                    src={
                                                        process.env.PUBLIC_URL + "/images/cto-signature.png"
                                                    }
                                                    alt="images" style={{height: '60px'}}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 dark">
                                <div className="story-right">
                                    <div className="title black">
                                        <span>Inside Story</span>
                                        <h2 className="mb-15 text-white">
                                            We are creative Agency that creates beautiful.
                                        </h2>
                                    </div>
                                    <p className="text-white">
                                        Integer purus odio, placerat nec rhoncus in, ullamcorper nec
                                        dolor. Classe aptent taciti sociosqu ad litora torquent per
                                        conubia nostra, per inceptosi himenaeos. Praesent nec neque
                                        at dolor venenatis consectetur eu quis e Donec lacinia
                                        placerat felis non aliquam.Integer purus odio.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default InsideStoryArea;
