const data = [
    {
        id:1,
        title:"DFW Towing",
        heading:"DFW Hook Towing",
        image:"images/portfolio/dfw.jpg",
        category: "Developing",
    },
    {
        id:2,
        title:"Rapid Dispatchy",
        heading:"Rapid Dispatchy",
        image:"images/portfolio/rd.jpg",
        category: "Developing",
    },
    {
        id:3,
        title:"Ceresrx",
        heading:"Medical Billing",
        image:"images/portfolio/ceresrx.jpg",
        category: "Developing",
    },
    {
        id:4,
        title:"Globo Science",
        heading:"Glogbo Science",
        image:"images/portfolio/globoscience.jpg",
        category: "UI/UX",
    },
    {
        id:5,
        title:"Tender",
        heading:"Tender Management System",
        image:"images/portfolio/tender.jpg",
        category: "",
    },
    {
        id:6,
        title:"Gillmon Health Care",
        heading:"Gillmon Health Care",
        image:"images/portfolio/5.jpg",
        category: "Web Design",
    },
    {
        id:7,
        title:"Quiz App",
        heading:"Quiz Manag Sys",
        image:"images/portfolio/quizapp.jpg",
        category: "Coding",
    },
    {
        id:8,
        title:"Book Store",
        heading:"Antoine Desulme Book store",
        image:"images/portfolio/8.jpg",
        category: "Coding",
    },
    {
        id:9,
        title:"Motor Club",
        heading:"Motor Club",
        image:"images/portfolio/1.jpg",
        category: "Graphic Design",
    },
    {
        id:10,
        title:"Pure Buddha",
        heading:"Pure Budhha Canada",
        image:"images/portfolio/11.jpg",
        category: "Graphic Design",
    },
    {
        id:11,
        title:"Tilala Qurtuba",
        heading:"T Q F C",
        image:"images/portfolio/tq.jpg",
        category: "Graphic Design",
    },
    {
        id:11,
        title:"life Care",
        heading:"Life Care Pharma",
        image:"images/portfolio/lifecarepharma.jpg",
        category: "Developing",
    },
];

export default data;
