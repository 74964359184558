import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import ServiceDetailsWrapper from "./ServiceDetailsWrapper";

function ServiceDetails() {
  return (
    <>
      <div className="dark">
        <Breadcrumb pageName="Service Details" />
        <ServiceDetailsWrapper />
      </div>
    </>
  );
}

export default ServiceDetails;
