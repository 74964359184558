import React from "react";
import {Link} from "react-router-dom";
import {SRLWrapper} from "simple-react-lightbox";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Autoplay, Navigation} from "swiper";

SwiperCore.use([Navigation, Autoplay]);

function FrontProjects(props) {
    const portfolioSlider = {
        slidesPerView: 5,
        spaceBetween: 30,
        loop: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        autoplay: {
            delay: 5000,
        },
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 2,
                spaceBetween: 30,
            },

            // when window width is >= 640px
            768: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
            // when window width is >= 992px
            992: {
                slidesPerView: 4,
                spaceBetween: 40,
            },
            // when window width is >= 1400px
            1400: {
                slidesPerView: 5,
                spaceBetween: 40,
            },
        },
    };
    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <>
            <SRLWrapper>
                <div className="portfolio-area sec-mar-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-7 col-xl-5">
                                <div className={`title ${props.black}`}>
                                    <span>Portfolio</span>

                                    <h2>Recent Projects </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper portfolio-slider">
                        <Swiper
                            {...portfolioSlider}
                            pagination={{
                                type: "bullets",
                                clickable: true,
                                // el:".swiper-pagination"
                            }}
                            className="swiper-wrapper"
                        >
                            <SwiperSlide className="swiper-slide">
                                <div className="single-portfolio">
                                    <div className="portfolio-data">
                                        <a href="/">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL + "/images/portfolio/8.jpg"
                                                }
                                                alt="Lead Developer"
                                            />
                                        </a>
                                    </div>
                                    <div className="portfolio-inner">
                                        <span>Book Store</span>
                                        <h4>Antoine Desulme</h4>

                                        <div className="portfolio-hover">
                                            <Link onClick={scrollTop} to="" className="case-btn">
                                                Case Study
                                            </Link>
                                            <a
                                                data-lightbox="image1"
                                                href={
                                                    process.env.PUBLIC_URL + "/images/portfolio/8.jpg"
                                                }
                                            >
                                                <i
                                                    alt="images"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        "/images/portfolio/search-2.svg"
                                                    }
                                                    className="fas fa-search"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="swiper-slide">
                                <div className="single-portfolio">
                                    <div className="portfolio-data">
                                        <a href="#s">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL + "/images/portfolio/dfw.jpg"
                                                }
                                                alt="images"
                                            />
                                        </a>
                                    </div>
                                    <div className="portfolio-inner">
                                        <span>DFW</span>
                                        <h4>Towing Services</h4>
                                        <div className="portfolio-hover">
                                            <Link onClick={scrollTop} to="" className="case-btn">
                                                Case Study
                                            </Link>
                                            <a
                                                data-lightbox="image1"
                                                href={
                                                    process.env.PUBLIC_URL +
                                                    "/images/portfolio/dfw.jpg"
                                                }
                                            >
                                                <img
                                                    alt="images"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        "/images/portfolio/search-2.svg"
                                                    }
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="swiper-slide">
                                <div className="single-portfolio">
                                    <div className="portfolio-data">
                                        <a href="#">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL + "/images/portfolio/tender.jpg"
                                                }
                                                alt="images"
                                            />
                                        </a>
                                    </div>
                                    <div className="portfolio-inner">
                                        <span>Tender</span>
                                        <h4>Tender Mgmt System</h4>
                                        <div className="portfolio-hover">
                                            <Link onClick={scrollTop} to="" className="case-btn">
                                                Case Study
                                            </Link>
                                            <a
                                                data-lightbox="image1"
                                                href={
                                                    process.env.PUBLIC_URL +
                                                    "/images/portfolio/tender.jpg"
                                                }
                                            >
                                                <img
                                                    alt="images"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        "/images/portfolio/search-2.svg"
                                                    }
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="swiper-slide">
                                <div className="single-portfolio">
                                    <div className="portfolio-data">
                                        <a href="#">
                                            <img
                                                src={process.env.PUBLIC_URL + "/images/portfolio/rd.jpg"}
                                                alt="images"
                                            />
                                        </a>
                                    </div>
                                    <div className="portfolio-inner">
                                        <span>Rapid Dispatchy</span>
                                        <h4>Dispatch Services</h4>
                                        <div className="portfolio-hover">
                                            <Link onClick={scrollTop} to="" className="case-btn">
                                                Case Study
                                            </Link>
                                            <a
                                                data-lightbox="image1"
                                                href={
                                                    process.env.PUBLIC_URL +
                                                    "/images/portfolio/rd.jpg"
                                                }
                                            >
                                                <img
                                                    alt="images"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        "/images/portfolio/search-2.svg"
                                                    }
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                        <div className="swiper-pagination"/>
                        <div className="swiper-button-next"/>
                        <div className="swiper-button-prev"/>
                    </div>
                </div>
            </SRLWrapper>
        </>
    );
}

export default FrontProjects;
