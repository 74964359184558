import React from "react";

function LetsTalkArea() {
    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <>
            <section className="lets-talk sec-pad">
                <div className="container">
                    <div className="row align-content-center">
                        <div className="col-md-6 col-lg-6 col-xl-6" >
                            <div className="title special">
                                <span>Let’s Talk </span>
                                <h2 >
                                    We would love to hear from you.
                                    <br/> Lets Talk About Your Next
                                  &nbsp;&nbsp; <b style={{paddingTop : '20px'}}>Project</b>
                                </h2>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-6">
                            <div className="contact-form">
                                <form
                                    onSubmit={(e) => e.preventDefault()}
                                    action="#"
                                    method="post"
                                >
                                    <div className="row">
                                        <div className="col-12">
                                            <input
                                                type="text"
                                                name="name" required
                                                placeholder="Enter your name"
                                            />
                                        </div>
                                        <div className="col-xl-6">
                                            <input
                                                type="email"
                                                name="email" required
                                                placeholder="Enter your work email"
                                            />
                                        </div>
                                        <div className="col-xl-6">
                                            <input
                                                type="text" required
                                                name="company_website"
                                                placeholder="Your's Company Website"
                                            />
                                        </div>
                                        <div className="col-xl-6 mb-5">
                                            <select className="form-select" style={{
                                                lineHeight: '2.5rem',
                                                borderRadius: '30px',
                                                width: '100%',
                                                padding: '0.5rem'
                                            }}>
                                                <option value="Select_Budget" selected>&nbsp;&nbsp;Select Budget</option>
                                                <option value="Select_Budget">None Just get Started</option>
                                                <option value="Select_Budget">Less then $5000</option>
                                                <option value="Select_Budget">$5000-$10000</option>
                                                <option value="Select_Budget">$20000-$30000</option>
                                                <option value="Select_Budget">$40000-$50000</option>
                                            </select>
                                        </div>

                                        <div className="col-xl-6 mb-5">
                                            <select className="form-select" style={{
                                                lineHeight: '2.5rem',
                                                borderRadius: '30px',
                                                width: '100%',
                                                padding: '0.5rem'
                                            }}>
                                                <option value="Select_Budget" selected>&nbsp;&nbsp;Hire Developer</option>
                                                <option value="Select_Budget">Junior Developer</option>
                                                <option value="Select_Budget">Senior Developer</option>
                                                <option value="Select_Budget">Full Stack Developer</option>
                                            </select>
                                        </div>
                                        <div className="col-12">
                                              <textarea
                                                  name="message"
                                                  cols={30} required
                                                  rows={10}
                                                  placeholder="Your message"
                                                  defaultValue={""}
                                              />
                                        </div>
                                        <div className="col-12">
                                            <input type="submit" defaultValue="Send Message"/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default LetsTalkArea;
