import React from "react";

function ProjectProcess() {
    return (
        <>
            <div className="project-process">
                <div className="row justify-content-between">
                    <div className="col">
                        <div className="process-step">
                            <h4>Client:</h4>
                            <span>Antoine Desulme</span>
                        </div>
                    </div>
                    <div className="col">
                        <div className="process-step">
                            <h4>Services:</h4>
                            <span>UI/UX Design,Developing</span>
                        </div>
                    </div>
                    <div className="col">
                        <div className="process-step">
                            <h4>Website:</h4>
                            <span>www.antoinedesulme.com/</span>
                        </div>
                    </div>
                    <div className="col">
                        <div className="process-step">
                            <h4>Start Date:</h4>
                            <span>09.03.2023</span>
                        </div>
                    </div>
                    <div className="col">
                        <div className="process-step">
                            <h4>End Date:</h4>
                            <span>01.05.2023</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="process-banner">
                            <img
                                src={process.env.PUBLIC_URL + "/images/process-banner.jpg"}
                                alt="images"
                            />
                        </div>
                    </div>
                </div>
                <div className="project-overview">
                    <h3>OVERVIEW</h3>
                    <div className="row">
                        <div className="col-md-5 col-lg-5 col-xl-5">
                            <div className="overview-img">
                                <img
                                    src={process.env.PUBLIC_URL + "/images/project/bookstore.png"}
                                    alt="images"
                                />
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-7 col-xl-7">
                            <div className="overview-content">
                                <h3>Our Client Work Brief</h3>
                                <p>
                                    The website will feature distinct categories of audio paper books and audio books,
                                    allowing users to easily browse and add items to their cart. Client reviews will
                                    provide valuable insights, and a secure payment method will enable smooth
                                    transactions. A comprehensive dashboard will offer administrators access to sales
                                    data and website statistics. The website will prioritize user experience with an
                                    intuitive interface, efficient search and filtering functionalities, and
                                    personalized user accounts. It will be optimized for various devices and regularly
                                    maintained for reliability.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="project-overview right">
                    <h3>PROJECT PROCESS</h3>
                    <div className="row">
                        <div className="col-md-6 col-lg-3 col-xl-3 or4">
                            <div className="project-single-step">
                                <h4>04. Developing</h4>
                                <div className="project-step-img">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/images/project/project-single-step-4.jpg"
                                        }
                                        alt="images"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 col-xl-3 or3">
                            <div className="project-single-step">
                                <h4>03. UI Design</h4>
                                <div className="project-step-img">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/images/project/project-single-step-3.jpg"
                                        }
                                        alt="images"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 col-xl-3 or2">
                            <div className="project-single-step">
                                <h4>02. Wireframe</h4>
                                <div className="project-step-img">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/images/project/project-single-step-2.jpg"
                                        }
                                        alt="images"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 col-xl-3 or1">
                            <div className="project-single-step">
                                <h4>01. Brainstorming</h4>
                                <div className="project-step-img">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/images/project/project-single-step-1.jpg"
                                        }
                                        alt="images"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="project-overview">
                    <h3>Check &amp; launch</h3>
                    <div className="row">
                        <div className="col-md-7 col-lg-7 col-xl-7 or2">
                            <div className="overview-content">
                                <p>
                                    Before launching the website, a thorough check will be conducted to ensure all
                                    features and functionalities are working as intended. This includes testing the
                                    browsing and cart functionalities, verifying the client review system, and ensuring
                                    a secure payment method is in place.The dashboard will be reviewed to confirm its
                                    accuracy in capturing and presenting essential statistics and sales data. Any
                                    necessary adjustments or improvements will be made to ensure administrators have
                                    access to reliable information.User experience will be a key focus during the
                                    checking process. The interface will be evaluated for its intuitiveness and
                                    responsiveness, while search and filtering functionalities will be tested to ensure
                                    they efficiently guide users to their desired books.User accounts will be thoroughly
                                    checked to confirm that they function properly, allowing users to save preferences,
                                    track order history, and manage personal information. Any bugs or issues will be
                                    addressed to provide a seamless and personalized experience for customers.The launch
                                    of the website marks the beginning of an exciting journey in providing a seamless
                                    and immersive online bookstore experience. Continuous feedback from users will be
                                    welcomed, and future updates and enhancements will be planned based on customer
                                    preferences and emerging trends.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-5 col-lg-5 col-xl-5 or1">
                            <div className="overview-img">
                                <img
                                    src={process.env.PUBLIC_URL + "/images/overview-2.jpg"}
                                    alt="images"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProjectProcess;
