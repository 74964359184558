import React from "react";
import LetsTalkArea from "../../common/LetsTalkArea";
import TestimonialArea from "../../common/TestimonialArea";
import WhyChooseUsArea from "../../common/WhyChooseUsArea";
import HeroArea from "../Home/HeroArea";
import OurPartnerArea from "../Home/OurPartnerArea";
import ServiceArea from "../Home/ServiceArea";
import About2Area from "./About2Area";
import FrontProjects from "../Home/FrontProjects";

function HomePage2(props) {
    return (
        <>
            <HeroArea/>
            <ServiceArea/>
            <About2Area/>
            <OurPartnerArea/>
            <FrontProjects black=""/>
            <WhyChooseUsArea black="" light="dark" lable="progressbar-label"/>
            <TestimonialArea/>
            {/*  this is blogs section*/}
            {/*<NewsLatterArea />*/}
            {/*  <ContactForm/>*/}
            <LetsTalkArea/>
        </>
    );
}

export default HomePage2;
