import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import ContactUsArea from "./ContactUsArea";
import ContactFormEnd from "./ContactFormEnd";

function ContactPage() {
  return (
    <>
      <Breadcrumb pageName="Contact Us" />
      <div
        className="contact-area sec-mar"
        style={{ paddingRight: "10px !important" }}
      >
        <ContactUsArea />
        <ContactFormEnd />
      </div>
    </>
  );
}

export default ContactPage;
