import React from "react";

function ContactUsArea() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col col-xl-6">
            <div className="title black">
              <span>Get In Touch</span>
              <h2>contact us if you have more questions.</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 col-xl-4">
            <div className="office-info">
              <div className="icon">
                <i className="bi bi-geo-alt" />
              </div>
              <h4>Location</h4>
              <p>
                3491 Covent Crescent, Mississauga, L5M 7K7, ON, Canada | Office
                # M-91, First Floor, Midway Centrum, 6th Rd, Rawalpindi, 46000
              </p>
              {""}
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xl-4">
            <div className="office-info">
              <div className="icon">
                <i className="bi bi-telephone" />
              </div>
              <h4>Phone</h4>
              <a href="tel:6172037988">+16 172037988</a>
              <a href="tel:03019333400"> +92 301 9333400</a>
              <a href="tel:6475744664"> +16 475744664</a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xl-4">
            <div className="office-info">
              <div className="icon">
                <i className="bi bi-envelope" />
              </div>
              <h4>Email</h4>
              <a href="mailto:info@example.com">info@asontechsol.com</a>
              <a href="mailto:support@example.com">support@asontechsol.com</a>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUsArea;
