import React from "react";
// import Swiper core and required modules
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Autoplay, EffectFade, Navigation, Pagination,} from "swiper";

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

function TestimonialArea() {
    const testimonialSlider = {
        slidesPerView: 1,
        loop: true,
        speed: 2000,
        autoplay: {
            delay: 5000,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    };
    return (
        <>
            <section className="testimonial-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="swiper testimonial-slider">
                                <Swiper
                                    pagination={{
                                        type: "fraction",
                                    }}
                                    {...testimonialSlider}
                                    className="swiper-wrapper"
                                >
                                    <SwiperSlide className="swiper-slide">
                                        <div className="testimonial-content">
                                            <div className="quote">
                                                <i className="fas fa-quote-left"/>
                                            </div>
                                            <div className="client-info">
                                                <div className="client-pic">
                                                    <img
                                                        src={process.env.PUBLIC_URL + "/images/testimonials/8.png"}
                                                        alt="images"
                                                    />
                                                </div>
                                                <div className="client-details">
                                                    <h4>Life Care Pharma</h4>
                                                    <span>Executive CEO</span>
                                                </div>
                                            </div>
                                            <p>
                                                <i className="fas fa-quote-left"/> Working with Asontechsol for Lifecare
                                                Pharma was a game-changer. Their expertise and dedication ensured our
                                                pharmaceutical project was a success. We're grateful for their
                                                exceptional service and positive impact on patient care.{" "}
                                                <i className="fas fa-quote-right"/>
                                            </p>
                                            <div className="rating">
                                                <i className="fas fa-star"/>
                                                <i className="fas fa-star"/>
                                                <i className="fas fa-star"/>
                                                <i className="fas fa-star"/>
                                                <i className="fas fa-star"/>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="swiper-slide">
                                        <div className="testimonial-content">
                                            <div className="quote">
                                                <i className="fas fa-quote-left"/>
                                            </div>
                                            <div className="client-info">
                                                <div className="client-pic">
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL + "/images/testimonials/4.png"
                                                        }
                                                        alt="images"
                                                    />
                                                </div>
                                                <div className="client-details">
                                                    <h4>Pure Buddha</h4>
                                                    <span>CTO Founder</span>
                                                </div>
                                            </div>
                                            <p>
                                                <i className="fas fa-quote-left"/>PureBuddha, brought to life by
                                                Asontechsol, exceeded our expectations. Their innovative approach and
                                                attention to detail resulted in a remarkable product line. We're
                                                thrilled with the natural products that have transformed our customers'
                                                well-being.{" "}
                                                <i className="fas fa-quote-right"/>
                                            </p>
                                            <div className="rating">
                                                <i className="fas fa-star"/>
                                                <i className="fas fa-star"/>
                                                <i className="fas fa-star"/>
                                                <i className="fas fa-star"/>
                                                <i className="fas fa-star"/>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="swiper-slide">
                                        <div className="testimonial-content">
                                            <div className="quote">
                                                <i className="fas fa-quote-left"/>
                                            </div>
                                            <div className="client-info">
                                                <div className="client-pic">
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL + "/images/testimonials/antoine.jpg"
                                                        }
                                                        alt="images"
                                                    />
                                                </div>
                                                <div className="client-details">
                                                    <h4>Antoine Desulme</h4>
                                                    <span>Managing Director</span>
                                                </div>
                                            </div>
                                            <p>
                                                <i className="fas fa-quote-left"/>Asontechsol played a vital role in
                                                creating an extraordinary online experience for Antoine Desulme Book
                                                Store. Their seamless integration of technology and aesthetics enhanced
                                                our customers' journey. We're grateful for their expertise and passion
                                                for literature.{" "}
                                                <i className="fas fa-quote-right"/>
                                            </p>
                                            <div className="rating">
                                                <i className="fas fa-star"/>
                                                <i className="fas fa-star"/>
                                                <i className="fas fa-star"/>
                                                <i className="fas fa-star"/>
                                                <i className="fas fa-star"/>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="swiper-slide">
                                        <div className="testimonial-content">
                                            <div className="quote">
                                                <i className="fas fa-quote-left"/>
                                            </div>
                                            <div className="client-info">
                                                <div className="client-pic">
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL + "/images/testimonials/2.png"
                                                        }
                                                        alt="images"
                                                    />
                                                </div>
                                                <div className="client-details">
                                                    <h4>DFW Towing</h4>
                                                    <span>Managing Director</span>
                                                </div>
                                            </div>
                                            <p>
                                                <i className="fas fa-quote-left"/>DFWhookitTowing experienced remarkable
                                                growth with Asontechsol's support. Their digital marketing strategies
                                                and user-friendly website design significantly improved our customer
                                                engagement. We're thankful for their prompt and reliable towing
                                                solutions{" "}
                                                <i className="fas fa-quote-right"/>
                                            </p>
                                            <div className="rating">
                                                <i className="fas fa-star"/>
                                                <i className="fas fa-star"/>
                                                <i className="fas fa-star"/>
                                                <i className="fas fa-star"/>
                                                <i className="fas fa-star"/>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                                <div className="swiper-button-next"/>
                                <div className="swiper-button-prev"/>
                                <div className="swiper-pagination"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TestimonialArea;
