import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import LetsTalkArea from "../../common/LetsTalkArea";
import HowWeWorkArea from "./HowWeWorkArea";
import ServicePrice from "./ServicePrice";
import WhatWeDoArea from "./WhatWeDoArea";
import ServiceArea from "../Home/ServiceArea";

function ServicesPage() {
  return (
    <>
      <div className="dark">
        <Breadcrumb pageName="Service" />
        {/*<WhatWeDoArea />*/}
          <ServiceArea />
        <HowWeWorkArea />
        {/* <ServicePrice /> */}
        <LetsTalkArea />
      </div>
    </>
  );
}

export default ServicesPage;
