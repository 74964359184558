/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

function ContactForm() {
  return (
    <>
      <div className="contact-information">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xl-6">
              <div className="contact-form">
                <h3 className="text-black">Have Any Questions</h3>
                <form action="https://formspree.io/f/xeqwjpwq" method="POST">
                  <input
                    name="subject"
                    hidden
                    value="AsontechSol Contact Us Form"
                  />
                  <div className="row">
                    <div className="col-12">
                      <input
                        type="text"
                        required
                        name="name"
                        placeholder="Enter your name"
                      />
                    </div>
                    <div className="col-xl-6">
                      <input
                        type="email"
                        required
                        name="email"
                        placeholder="Enter your work email"
                      />
                    </div>
                    <div className="col-xl-6">
                      <input
                        type="text"
                        required
                        name="Company_Website"
                        placeholder="Your's Company Website"
                      />
                    </div>
                    <div className="col-xl-6 mb-5">
                      <select
                        className="form-select"
                        name="Select_Budget"
                        style={{
                          lineHeight: "2.5rem",
                          borderRadius: "30px",
                          width: "100%",
                          padding: "0.5rem",
                        }}
                      >
                        <option value="Select_Budget" selected>
                          &nbsp;&nbsp;Select Budget
                        </option>
                        <option value="Select_Budget">
                          None Just get Started
                        </option>
                        <option value="Select_Budget">Less then $5000</option>
                        <option value="Select_Budget">$5000-$10000</option>
                        <option value="Select_Budget">$20000-$30000</option>
                        <option value="Select_Budget">$40000-$50000</option>
                      </select>
                    </div>

                    <div className="col-xl-6 mb-5">
                      <select
                        className="form-select"
                        name="Hire_developer"
                        style={{
                          lineHeight: "2.5rem",
                          borderRadius: "30px",
                          width: "100%",
                          padding: "0.5rem",
                        }}
                      >
                        <option value="Hire_developer" selected>
                          &nbsp;&nbsp;Hire Developer
                        </option>
                        <option value="Hire_developer">Junior Developer</option>
                        <option value="Hire_developer">Senior Developer</option>
                        <option value="Hire_developer">
                          Full Stack Developer
                        </option>
                      </select>
                    </div>
                    <div className="col-12">
                      <textarea
                        name="Message"
                        cols={30}
                        required
                        rows={10}
                        placeholder="Your message"
                        defaultValue={""}
                      />
                    </div>
                    <div className="col-12">
                      <button
                        type="submit"
                        className="btn btn-dark p-2 px-4 py-2 rounded-pill nav-pills"
                        defaultValue="Send Message"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6 p-3">
              <div className="google-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3321.524485702129!2d73.07530437617011!3d33.64356947331363!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38df952ad872980b%3A0x6c333bdb54a21277!2sAsonTech%20Solutions%20(Pvt)%20Ltd!5e0!3m2!1sen!2s!4v1727632205060!5m2!1sen!2s"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactForm;
